import React from "react";
import Layout from "../ui/layouts/Layout";

const pnp = () => {
  return (
    <div style={{ backgroundColor: "rgba(9, 0, 43, 1)" }}>
      <Layout alwayNavbarVisible style={{ color: "white" }}>
        <div style={{ marginTop: "100px", backgroundColor: "white", padding: "30px" }}>
          <h2>PRIVACY NOTICE</h2>
          <p style={{ textAlign: "justify" }}>


            Last updated January 01, 2021 <br/><br/>


            Thank you for choosing to be part of our community at Elnakeeb Pty Ltd, doing business as The Digital BA
            ("The
            Digital BA", "we", "us", "our"). We are committed to protecting your personal information and your right to
            privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to
            your personal information, please contact us at hello@thedigitalba.com.au.
            <br/><br/>
            When you visit our website http://thedigitalba.com.au (the "Website"), use our mobile application, use our
            Facebook application, as the case may be (the "App") and more generally, use any of our services (the
            "Services", which include the Website and App), we appreciate that you are trusting us with your personal
            information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the
            clearest way possible what information we collect, how we use it and what rights you have in relation to it.
            We hope you take some time to read through it carefully, as it is important. If there are any terms in this
            privacy notice that you do not agree with, please discontinue use of our Services immediately.
            <br/><br/>
            This privacy notice applies to all information collected through our Services (which, as described above,
            includes our Website and App), as well as, any related services, sales, marketing or events.
            <br/><br/>
            Please read this privacy notice carefully as it will help you understand what we do with the information
            that
            we collect. <br/>
            <br/><br/>
            <b> TABLE OF CONTENTS </b><br/>

            1. WHAT INFORMATION DO WE COLLECT? <br/>
            2. HOW DO WE USE YOUR INFORMATION?<br/>
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?<br/>
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?<br/>
            5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?<br/>
            6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?<br/>
            7. HOW LONG DO WE KEEP YOUR INFORMATION?<br/>
            8. HOW DO WE KEEP YOUR INFORMATION SAFE?<br/>
            9. DO WE COLLECT INFORMATION FROM MINORS?<br/>
            10. WHAT ARE YOUR PRIVACY RIGHTS?<br/>
            11. CONTROLS FOR DO-NOT-TRACK FEATURES<br/>
            12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?<br/>
            13. DO WE MAKE UPDATES TO THIS NOTICE?<br/>
            14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?<br/>
            15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?<br/><br/>

            <b> <b>1. WHAT INFORMATION DO WE COLLECT?</b>
            </b> <br/>

            Information automatically collected<br/>

            <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and
            device characteristics — is collected automatically when you visit our Services.<br/>
            <br/>
            We automatically collect certain information when you visit, use or navigate the Services. This information
            does not reveal your specific identity (like your name or contact information) but may include device and
            usage information, such as your IP address, browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location, information about how and when you use our
            Services and other technical information. This information is primarily needed to maintain the security and
            operation of our Services, and for our internal analytics and reporting purposes.
            <br/><br/>
            Like many businesses, we also collect information through cookies and similar technologies.
            <br/><br/>
            The information we collect includes:<br/>
            <ul>
              <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance
                information
                our servers automatically collect when you access or use our Services and which we record in log files.
                Depending on how you interact with us, this log data may include your IP address, device information,
                browser type and settings and information about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed, searches and other actions you take such as
                which features you use), device event information (such as system activity, error reports (sometimes
                called 'crash dumps') and hardware settings).
              </li>
              <li> Device Data. We collect device data such as information about your computer, phone, tablet or other
                device you use to access the Services. Depending on the device used, this device data may include
                information such as your IP address (or proxy server), device and application identification numbers,
                location, browser type, hardware model Internet service provider and/or mobile carrier, operating system
                and system configuration information.
              </li>
              <li> Location Data. We collect location data such as information about your device's location, which can
                be
                either precise or imprecise. How much information we collect depends on the type and settings of the
                device you use to access the Services. For example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on your IP address). You can opt out of
                allowing us to collect this information either by refusing access to the information or by disabling
                your
                Location setting on your device. Note however, if you choose to opt out, you may not be able to use
                certain aspects of the Services.
              </li>
            </ul>
            <br/>

            <b>Information collected through our App</b>
            <br/>
            <strong>In Short:</strong> We collect information regarding your geo-location, mobile device, push
            notifications, and Facebook permissions when you use our App.
            <br/><br/>
            If you use our App, we also collect the following information:
            <ul>
              <li>Geo-Location Information. We may request access or permission to and track location-based information
                from your mobile device, either continuously or while you are using our App, to provide certain
                location-based services. If you wish to change our access or permissions, you may do so in your device's
                settings.
              </li>
              <li> Mobile Device Access. We may request access or permission to certain features from your mobile
                device,
                including your mobile device's storage, contacts, reminders, sensors, social media accounts, calendar,
                bluetooth, and other features. If you wish to change our access or permissions, you may do so in your
                device's settings.
              </li>
              <li> Mobile Device Data. We automatically collect device information (such as your mobile device ID, model
                and manufacturer), operating system, version information and system configuration information, device
                and
                application identification numbers, browser type and version, hardware model Internet service provider
                and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App,
                we
                may also collect information about the phone network associated with your mobile device, your mobile
                device’s operating system or platform, the type of mobile device you use, your mobile device’s unique
                device ID and information about the features of our App you accessed.
              </li>
              <li> Push Notifications. We may request to send you push notifications regarding your account or certain
                features of the App. If you wish to opt-out from receiving these types of communications, you may turn
                them off in your device's settings.
              </li>
              <li> Facebook Permissions. We by default access your Facebook basic account information, including your
                name, email, gender, birthday, current city, and profile picture URL, as well as other information that
                you choose to make public. We may also request access to other permissions related to your account, such
                as friends, checkins, and likes, and you may choose to grant or deny us access to each individual
                permission. For more information regarding Facebook permissions, refer to the Facebook Permissions
                Reference page.
              </li>
            </ul>
            This information is primarily needed to maintain the security and operation of our App, for troubleshooting
            and for our internal analytics and reporting purposes.
            <br/><br/>
            <b>2. HOW DO WE USE YOUR INFORMATION?</b>
            <br/>
            <strong>In Short:</strong> We process your information for purposes based on legitimate business interests,
            the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
            <br/><br/>
            We use personal information collected via our Services for a variety of business purposes described below.
            We
            process your personal information for these purposes in reliance on our legitimate business interests, in
            order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
            <br/><br/>
            We use the information we collect or receive:
            <ul>
              <li> To facilitate account creation and logon process. If you choose to link your account with us to a
                third-party account (such as your Google or Facebook account), we use the information you allowed us to
                collect from those third parties to facilitate account creation and logon process for the performance of
                the contract. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for further
                information.
              </li>
              <li> To post testimonials. We post testimonials on our Services that may contain personal information.
                Prior
                to posting a testimonial, we will obtain your consent to use your name and the content of the
                testimonial.
                If you wish to update, or delete your testimonial, please contact us at hello@thedigitalba.com.au and be
                sure to include your name, testimonial location, and contact information.
              </li>
              <li> Request feedback. We may use your information to request feedback and to contact you about your use
                of
                our Services.
              </li>
              <li> To enable user-to-user communications. We may use your information in order to enable user-to-user
                communications with each user's consent.
              </li>
              <li> To manage user accounts. We may use your information for the purposes of managing our account and
                keeping it in working order.
              </li>
              <li> To send administrative information to you. We may use your personal information to send you product,
                service and new feature information and/or information about changes to our terms, conditions, and
                policies.
              </li>
              <li> To protect our Services. We may use your information as part of our efforts to keep our Services safe
                and secure (for example, for fraud monitoring and prevention).
              </li>
              <li> To enforce our terms, conditions and policies for business purposes, to comply with legal and
                regulatory requirements or in connection with our contract.
              </li>
              <li> To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we
                may
                need to inspect the data we hold to determine how to respond.
              </li>
              <li> Fulfill and manage your orders. We may use your information to fulfill and manage your orders,
                payments, returns, and exchanges made through the Services.
              </li>
              <li> Administer prize draws and competitions. We may use your information to administer prize draws and
                competitions when you elect to participate in our competitions.
              </li>
              <li> To deliver and facilitate delivery of services to the user. We may use your information to provide
                you
                with the requested service.
              </li>
              <li> To respond to user inquiries/offer support to users. We may use your information to respond to your
                inquiries and solve any potential issues you might have with the use of our Services.
              </li>
              <li> To send you marketing and promotional communications. We and/or our third-party marketing partners
                may
                use the personal information you send to us for our marketing purposes, if this is in accordance with
                your
                marketing preferences. For example, when expressing an interest in obtaining information about us or our
                Services, subscribing to marketing or otherwise contacting us, we will collect personal information from
                you. You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
              </li>
              <li> Deliver targeted advertising to you. We may use your information to develop and display personalized
                content and advertising (and work with third parties who do so) tailored to your interests and/or
                location
                and to measure its effectiveness.
              </li>
              <li> For other business purposes. We may use your information for other business purposes, such as data
                analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to
                evaluate and improve our Services, products, marketing and your experience. We may use and store this
                information in aggregated and anonymized form so that it is not associated with individual end users and
                does not include personal information. We will not use identifiable personal information without your
                consent.
              </li>
            </ul> <br/>

            <b>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE? </b>
            <br/>
            <strong>In Short:</strong> We only share information with your consent, to comply with laws, to provide you
            with services, to protect your rights, or to fulfill business obligations.
            <br/><br/>
            We may process or share your data that we hold based on the following legal basis:
            <ul>
              <li> Consent: We may process your data if you have given us specific consent to use your personal
                information for a specific purpose.
              </li>
              <li> Legitimate Interests: We may process your data when it is reasonably necessary to achieve our
                legitimate business interests.
              </li>
              <li> Performance of a Contract: Where we have entered into a contract with you, we may process your
                personal
                information to fulfill the terms of our contract.
              </li>
              <li> Legal Obligations: We may disclose your information where we are legally required to do so in order
                to
                comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process,
                such as in response to a court order or a subpoena (including in response to public authorities to meet
                national security or law enforcement requirements).
              </li>
              <li> Vital Interests: We may disclose your information where we believe it is necessary to investigate,
                prevent, or take action regarding potential violations of our policies, suspected fraud, situations
                involving potential threats to the safety of any person and illegal activities, or as evidence in
                litigation in which we are involved.
              </li>
            </ul>
            More specifically, we may need to process your data or share your personal information in the following
            situations:
            <ul>
              <li> Business Transfers. We may share or transfer your information in connection with, or during
                negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of
                our
                business to another company.
              </li>
              <li> Other Users. When you share personal information (for example, by posting comments, contributions or
                other content to the Services) or otherwise interact with public areas of the Services, such personal
                information may be viewed by all users and may be publicly made available outside the Services in
                perpetuity. If you interact with other users of our Services and register for our Services through a
                social network (such as Facebook), your contacts on the social network will see your name, profile
                photo,
                and descriptions of your activity. Similarly, other users will be able to view descriptions of your
                activity, communicate with you within our Services, and view your profile.
              </li>
            </ul>
            <br/>


            <b> 4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </b> <br/>
            <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your
            information.
            <br/><br/>
            We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
            information. Specific information about how we use such technologies and how you can refuse certain cookies
            is
            set out in our Cookie Notice.
            <br/><br/>
            <b> 5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </b> <br/>
            <strong>In Short:</strong> If you choose to register or log in to our services using a social media account,
            we may have access to certain information about you.
            <br/><br/>
            Our Services offers you the ability to register and login using your third-party social media account
            details
            (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
            information about you from your social media provider. The profile Information we receive may vary depending
            on the social media provider concerned, but will often include your name, email address, friends list,
            profile
            picture as well as other information you choose to make public on such social media platform. If you login
            using Facebook, we may also request access to other permissions related to your account, such as your
            friends,
            check-ins, and likes, and you may choose to grant or deny us access to each individual permission.
            <br/><br/>
            We will use the information we receive only for the purposes that are described in this privacy notice or
            that
            are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not
            responsible for, other uses of your personal information by your third-party social media provider. We
            recommend that you review their privacy notice to understand how they collect, use and share your personal
            information, and how you can set your privacy preferences on their sites and apps.
            <br/><br/>
            <b> 6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </b> <br/>
            <strong>In Short:</strong> We may transfer, store, and process your information in countries other than your
            own.
            <br/><br/>
            Our servers are located in. If you are accessing our Services from outside, please be aware that your
            information may be transferred to, stored, and processed by us in our facilities and by those third parties
            with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above),
            in and other countries.
            <br/><br/>
            If you are a resident in the European Economic Area, then these countries may not necessarily have data
            protection laws or other similar laws as comprehensive as those in your country. We will however take all
            necessary measures to protect your personal information in accordance with this privacy notice and
            applicable
            law.
            <br/><br/>
            <b> 7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </b> <br/>
            <strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes
            outlined
            in this privacy notice unless otherwise required by law.
            <br/><br/>
            We will only keep your personal information for as long as it is necessary for the purposes set out in this
            privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or
            other legal requirements). No purpose in this notice will require us keeping your personal information for
            longer than the period of time in which users have an account with us.
            <br/><br/>
            When we have no ongoing legitimate business need to process your personal information, we will either delete
            or anonymize such information, or, if this is not possible (for example, because your personal information
            has
            been stored in backup archives), then we will securely store your personal information and isolate it from
            any
            further processing until deletion is possible.
            <br/><br/>
            <b> 8. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </b> <br/>
            <strong>In Short:</strong> We aim to protect your personal information through a system of organizational
            and
            technical security measures.
            <br/><br/>
            We have implemented appropriate technical and organizational security measures designed to protect the
            security of any personal information we process. However, despite our safeguards and efforts to secure your
            information, no electronic transmission over the Internet or information storage technology can be
            guaranteed
            to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized
            third
            parties will not be able to defeat our security, and improperly collect, access, steal, or modify your
            information. Although we will do our best to protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should only access the Services within a
            secure
            environment.
            <br/><br/>
            <b> 9. DO WE COLLECT INFORMATION FROM MINORS?
            </b> <br/>
            <strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of
            age.
            <br/><br/>
            We do not knowingly solicit data from or market to children under 18 years of age. By using the Services,
            you
            represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to
            such
            minor dependent’s use of the Services. If we learn that personal information from users less than 18 years
            of
            age has been collected, we will deactivate the account and take reasonable measures to promptly delete such
            data from our records. If you become aware of any data we may have collected from children under age 18,
            please contact us at hello@thedigitalba.com.au.
            <br/><br/>
            <b> 10. WHAT ARE YOUR PRIVACY RIGHTS?
            </b> <br/>
            <strong>In Short:</strong> You may review, change, or terminate your account at any time.
            <br/><br/>
            If you are a resident in the European Economic Area and you believe we are unlawfully processing your
            personal
            information, you also have the right to complain to your local data protection supervisory authority. You
            can
            find their contact details here:
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            <br/><br/>
            If you are a resident in Switzerland, the contact details for the data protection authorities are available
            here: https://www.edoeb.admin.ch/edoeb/en/home.html.
            <br/><br/>
            If you have questions or comments about your privacy rights, you may email us at hello@thedigitalba.com.au.
            <br/><br/>
            Account Information
            <br/><br/>
            If you would at any time like to review or change the information in your account or terminate your account,
            you can:
            Contact us using the contact information provided.
            <br/><br/>
            Upon your request to terminate your account, we will deactivate or delete your account and information from
            our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
            problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal
            requirements.
            <br/><br/>
            Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you
            can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove
            cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of
            interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/.
            <br/><br/>
            Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on
            the unsubscribe link in the emails that we send or by contacting us using the details provided below. You
            will
            then be removed from the marketing email list — however, we may still communicate with you, for example to
            send you service-related emails that are necessary for the administration and use of your account, to
            respond
            to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
            Access your account settings and update your preferences.
            <br/><br/>

            <b> 11. CONTROLS FOR DO-NOT-TRACK FEATURES
            </b> <br/>
            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
            feature or setting you can activate to signal your privacy preference not to have data about your online
            browsing activities monitored and collected. At this stage no uniform technology standard for recognizing
            and
            implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or
            any other mechanism that automatically communicates your choice not to be tracked online. If a standard for
            online tracking is adopted that we must follow in the future, we will inform you about that practice in a
            revised version of this privacy notice.
            <br/><br/>
            <b> 12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </b> <br/>
            <strong>In Short:</strong> Yes, if you are a resident of California, you are granted specific rights
            regarding
            access to your personal information.
            <br/><br/>
            California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
            California residents to request and obtain from us, once a year and free of charge, information about
            categories of personal information (if any) we disclosed to third parties for direct marketing purposes and
            the names and addresses of all third parties with which we shared personal information in the immediately
            preceding calendar year. If you are a California resident and would like to make such a request, please
            submit
            your request in writing to us using the contact information provided below.
            <br/><br/>
            If you are under 18 years of age, reside in California, and have a registered account with a Service, you
            have
            the right to request removal of unwanted data that you publicly post on the Services. To request removal of
            such data, please contact us using the contact information provided below, and include the email address
            associated with your account and a statement that you reside in California. We will make sure the data is
            not
            publicly displayed on the Services, but please be aware that the data may not be completely or
            comprehensively
            removed from all our systems (e.g. backups, etc.).
            <br/><br/>
            <b> 13. DO WE MAKE UPDATES TO THIS NOTICE?
            </b> <br/>
            <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant
            laws.
            <br/><br/>
            We may update this privacy notice from time to time. The updated version will be indicated by an updated
            "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
            changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or
            by
            directly sending you a notification. We encourage you to review this privacy notice frequently to be
            informed
            of how we are protecting your information.
            <br/><br/>
            <b> 14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </b> <br/>
            If you have questions or comments about this notice, you may email us at hello@thedigitalba.com.au or by
            post
            to:
            <br/><br/>
            Elnakeeb Pty Ltd <br/>
            Grosvenor Place 225 George St <br/>
            Sydney, New South Wales 2000 <br/>
            Australia <br/><br/>

            <b> 15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? <br/>
            </b>
            Based on the applicable laws of your country, you may have the right to request access to the personal
            information we collect from you, change that information, or delete it in some circumstances. To request to
            review, update, or delete your personal information, please submit a request form by emailing
            hello@thedigitalba.com.au. We will respond to your request within 30 days.
          </p>
        </div>
      </Layout>
    </div>
  );
};

export default pnp;
